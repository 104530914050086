<template>
  <div>
    <div class="container-fluid pt-4 pb-5">
      <div class="holder d-flex flex-lg-row flex-column pt-0 pt-lg-5 mb-5">
        <!--Limits-->
        <div class="panel basic text-left d-flex flex-column align-items-center mr-0 mr-md-5">
          <span class="mt-0">{{$t('account_type_selection.basic.your_account')}}</span>
          <h2 class="font-weight-bold mx-auto mb-3">{{$t('account_type_selection.basic.header')}}</h2>
          <hr class="w-75">
          <p class="px-5 px-md-0 text-center">{{$t('account_limits.copy')}}</p>
          <div class="d-flex flex-row w-100 justify-content-around px-5 mb-5 mb-lg-0">
            <!-- tasks sold -->
            <div class="counter d-flex flex-column align-items-center position-relative sold" v-if="this.loaded">
              <div class="d-flex flex-column justify-content-center align-items-center info">
                <h5 v-if="(this.sellLimit - this.sellAmountLeft).toFixed(2) >= 99.99" class="font-weight-bold mb-0">${{ (this.sellLimit - this.sellAmountLeft).toFixed(2) }}</h5>
                <h4 v-else class="font-weight-bold mb-0">${{ (this.sellLimit - this.sellAmountLeft).toFixed(2) }}</h4>
                <span class="font-weight-bold mb-0">{{$t('account_limits.sold')}}</span>
              </div>
              <svg width="160" height="160" xmlns="http://www.w3.org/2000/svg">
              <g>
                <circle class="circle_background" r="50" cy="81" cx="81" stroke-width="15" stroke="#eee" fill="none"/>
                <circle class="circle_animation" r="50" cy="81" cx="81" stroke-width="15" :stroke-dashoffset="totalsellLimitsCalculated" stroke-dasharray="440" stroke="#f07f13" fill="none"/>
              </g>
              </svg>
              <p class="text-center">${{this.sellLimit}}&nbsp;{{$t('account_limits.max_sell_limit')}}*</p>
            </div>
            <!-- amount of tasks -->
            <div class="counter d-flex flex-column align-items-center position-relative tasks" v-if="this.loaded">
              <div class="d-flex flex-column justify-content-center align-items-center info">
                <h2 class="font-weight-bold mb-0">{{this.tasksLimit - this.tasksAmountLeft}}</h2>
                <span class="font-weight-bold" v-if="this.tasksLimit - this.tasksAmountLeft <= 1">{{$t('account_limits.task')}}</span><span class="font-weight-bold" v-else>{{$t('account_limits.tasks')}}</span>
              </div>
              <svg width="160" height="160" xmlns="http://www.w3.org/2000/svg">
              <g>
                <circle class="circle_background" r="50" cy="81" cx="81" stroke-width="15" stroke="#eee" fill="none"/>
                <circle class="circle_animation" r="50" cy="81" cx="81" stroke-width="15" :stroke-dashoffset="totalTasksCalculated" stroke-dasharray="440" stroke="#2ecc71" fill="none"/>
              </g>
              </svg>
              <p class="text-center">{{$t('account_limits.maximum')}}&nbsp;{{this.tasksLimit}}&nbsp;{{$t('account_limits.tasks')}}</p>
            </div>
            <!-- task limit -->
            <div class="counter d-flex flex-column align-items-center position-relative limit" v-if="this.loaded">
              <div class="d-flex flex-column justify-content-center align-items-center info">
                <h2 class="font-weight-bold mb-0">${{this.sellLimit >= 100 ? 100 : 50}}</h2>
              </div>
              <span></span>
              <svg width="160" height="160" xmlns="http://www.w3.org/2000/svg">
              <g>
                <circle class="circle_background" r="50" cy="81" cx="81" stroke-width="15" stroke="#eee" fill="none"/>
                <circle class="circle_animation" r="50" cy="81" cx="81" stroke-width="15" :stroke-dashoffset="costLimit" stroke-dasharray="440" stroke="#44aae1" fill="none"/>
              </g>
              </svg>
              <p class="text-center">${{this.sellLimit >= 100 ? 100 : 50}}&nbsp;{{$t('account_limits.task_limit')}}</p>
            </div>
            <!-- loader -->
            <div class="d-flex justify-content-center align-items-center w-100" v-else>
              <b-spinner class="mt-3 mt-lg-5" large label="Large Spinner" />
            </div>
          </div>
        </div>
        <!--verified-->
        <VerificationBlock :accountLimits="true"/>
      </div>
      <p class="text-left small"><i>{{$t('account_type_selection.terms_one')}}</i></p>
      <p class="text-left small"><i>{{$t('account_type_selection.terms_two')}}</i></p>
    </div>
  </div>
</template>

<script>
import ApiService from '../../apiService'
import VerificationBlock from '../../components/MyAccount/VerificationBlock'

export default {
  name: 'AccountLimits',
  components: {
    VerificationBlock
  },
  data () {
    return {
      costLimit: 440,
      totalTasksCalculated: 440,
      totalsellLimitsCalculated: 440,
      sellLimit: '',
      sellAmountLeft: '',
      tasksLimit: '',
      tasksAmountLeft: '',
      loaded: false
    }
  },
  mounted () {
    this.getUser()
  },
  methods: {
    getUser () {
      ApiService.getUser().then((response) => {
        if (response.data.data.user_limit[0].limit_key === 1) {
          this.sellLimit = response.data.data.user_limit[0].original_limit
          this.sellAmountLeft = response.data.data.user_limit[0].limit_left
          this.tasksLimit = response.data.data.user_limit[1].original_limit
          this.tasksAmountLeft = response.data.data.user_limit[1].limit_left
        } else {
          this.sellLimit = response.data.data.user_limit[1].original_limit
          this.sellAmountLeft = response.data.data.user_limit[1].limit_left
          this.tasksLimit = response.data.data.user_limit[0].original_limit
          this.tasksAmountLeft = response.data.data.user_limit[0].limit_left
        }
        setTimeout(() => {
          this.maxLimitsCalc()
          this.costLimit = 125
        }, 200)
      }).catch(() => { })
    },
    maxLimitsCalc () {
      // sell limit
      this.sellPercentage = ((this.sellLimit - this.sellAmountLeft) / this.sellLimit) * 100
      this.sellLimitCalculated = Math.PI * this.sellPercentage
      this.totalsellLimitsCalculated = 440 - this.sellLimitCalculated
      // task limit
      this.taskPercentage = ((this.tasksLimit - this.tasksAmountLeft) / this.tasksLimit) * 100
      this.tasksCalculated = Math.PI * this.taskPercentage
      this.totalTasksCalculated = 440 - this.tasksCalculated
      this.loaded = true
    }
  }
}
</script>

<style lang="scss">

.counter {
  .info {
    line-height: 20px;
    color: $brand-navy;
    position: absolute;
    left: 0;
    right: 0;
    top: 20px;
    bottom: 64px;
  }
  svg {
   -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .circle_animation {
    transition: all 1s;
  }
}

.container-fluid {
  max-width: 1140px !important;
}

.blue {
  color: $brand-sky;
}
.panel {
  &.basic {
    flex: 3;
    padding-top: 40px;
  }
  &.verified {
    flex: 1;
    margin-top: 60px;
  }
  background-repeat: no-repeat;
  border-radius: 12px;
  border: transparent;
  box-shadow: $connecting-btn-shadow;
  font-family: $header-font;
  font-size: 16px;
  white-space: pre-wrap;
  transition: all .2s ease-in-out;
  margin: 0 auto;
  background: white;
  width: 100%;
  &:hover {
    box-shadow: $connecting-btn-hover-shadow;
    transform: scale(1.025);
    transition: all .2 ease-in-out;
  }
}

@include lg-up {
  .panel {
    &.basic {
      padding-top: 60px;
    }
  }
}

</style>
